export default { // 自行分类¥
  global: {
    CapabilityAreas: "能力领域",
    // CapabilityAreasE: 'Capability areas',
    IntelligentManufacturing: '智能制造',
    lianghua: '两化融合',
    ProductsServices: "产品与服务",
    IntegrationTransformation: '集成转型服务',
    LowCarbon: '低碳改造',
    DataIntegration: '数据集成',
    DigitalTwin: '数字孪生',
    SmartParkTitle: '智慧园区',
    SmartBuildings: '智慧建筑',
    GreenFactory: '绿色工厂',
    IndustrialInternet: '工业互联网',
    videoUrl: "https://video.ucloudy.cn/vod-7d3fbf/029f07f03b3b71eebfe96632b68f0102/4df6a283ae1844e6be5645b828969ef0-e80d250f5ff64da6de36da29ed12a74c-sd.m3u8"
  },
  header: {
    Solutions: '解决方案',
    About: '关于我们',
    DiagnosticConsulting: '诊断咨询服务',
    grade: '智能制造成熟度',
    AI: 'AI机器视觉识别',
  },
  // 主页
  home: {
    DigitalSmart: '数字化转型·智能化改造',
    SmartFactory: "智能工厂诊断咨询",
    Innovation: '科创属性打造',
    DigitalTransformation: '数字化转型服务',
    PolicyGuidance: '政策辅导培训',
    MainBusiness: '主营业务',
    MainBusinessTitle: "体系认定、贯标",
    MainBusiness1: '智能制造成熟度模型',
    MainBusiness2: '两化融合贯标',
    MainBusiness3: '专精特新辅导',
    MainBusiness4: '工业互联网',
    Integrated: '集成服务',
    Integrated1: '祐云',
    Integrated2: '工厂',
    Integrated3: '基础网络',
    Integrated4: '工业软件',
    Integrated5: '智能装备',
    Integrated6: '工程设计',
    IntegratedMobile: require('@/assets/images/IntegratedMobileZh.png'),
    IntegratedPc: require('@/assets/images/IntegratedPcZh.png'),
    PolicyDeclaration: '政策申报',
    PolicyDeclaration1: '智能制造车间',
    PolicyDeclaration2: '智能制造工厂',
    PolicyDeclaration3: '智能制造示范场景',
    PolicyDeclaration4: '我们的优势',
    Products: require('@/assets/images/Products-pc.png'),
    Solutions: '解决方案',
    SolutionsName1: '500强外资企业',
    SolutionsTitle1: '轮胎追溯系统',
    Solutions1: '软硬件定制',
    Solutions2: 'AI视觉识别',
    Solutions3: '产线定位',
    Solutions4: '出入仓对接',
    SolutionsName5: 'A股上市公司',
    SolutionsTitle6: '电池隔膜质量检测系统',
    Solutions7: '缺陷识别',
    Solutions8: '全流程(MES)对接',
    Solutions9: '硬件设计',
    Solutions10: '软件开发',
    SolutionsName11: '台湾橡胶企业',
    SolutionsTitle12: '胶块疵点识别系统',
    Solutions13: '软硬件定制',
    Solutions14: '产线定位',
    Solutions15: 'AI视觉识别',
    SolutionsName16: '实验教学',
    SolutionsTitle17: '智慧实验室整体解决方案',
    Solutions18: 'LLM',
    Solutions19: '开放识别',
    Solutions20: '多模态融合',
    Solutions21: '实验室环境模拟',
    SolutionsName22: '500强外资企业',
    SolutionsTitle23: '双碳追溯系统',
    Solutions24: '碳核算',
    Solutions25: '双碳推演',
    Solutions26: '碳数据建模',
    Solutions27: 'DTC+定制+组合',
    DiagnosticServices: "诊断服务客户",
    DiagnosticServices1: '服务长三角地区近200家制造型企业',
    Technology: '技术合作伙伴',
    About: '关于我们',
    AboutContent: '祐云信息是一家典型的科技型企业，核心团队主要来自于有美留学工作经验的博士和硕士组成，公司的核心技术是AI工业机器视觉装备和配套的解决方案，是在智能制造的工业生产中应用广泛，同时也能为客户提供包括基础网络（特别是5G专网）建设，服务器搭建，数据平台等一系列集成服务，入选了上海地区经信委和南通市工信局的智能工厂诊断商和转型服务商名录。目前公司有5名国家智能制造成熟度模型评估师，4名两化融合咨询师。公司是中国电信终端产业协会成员，世界通讯协会GSMA成员，上海人工智能发展联盟，上海5G产业联盟，中国智能制造系统解决方案供应商联盟成员，上海大学/上海第二工业大学研究生培养基地。2023年，祐云信息是上海经信委监管的上海市智能制造产业协会监事单位，松江区、闵行区、南通市政府智能工厂指定智能工厂诊断咨询+转型服务商，是目前国内智能制造领域少数能提供诊断咨询和数字化转型集成服务的领军企业之一。',
    Contact: '联系我们',
    YourName: '您的姓名',
    Phone: '联系电话',
    Consultation: '咨询内容',
    Submit: '提交',
    Email: '邮箱',
    Address: '公司地址',
    AddressDetails: '上海市浦东新区祥科路58号A座505',
    phone1: '联系电话 / 186-1652-9115',
    Email1: `联系邮箱 / john{'@'}ucloudy.cn`,
    Address1: '公司地址 / 上海市浦东新区祥科路58号A座505',
  },
  // 两化
  lianghua: {
    title: "诊断咨询服务·两化融合",
    CustomerCases: '客户案例',
    CustomerCases1: '中天钢铁集团（南通）有限公司',
    CustomerCases2: '上海大风实验室设备有限公司',
    CustomerCases3: '苏州航发航空零部件有限公司',
    lianghuaConcept: '两化当前概念',
    lianghuaImg: require('@/assets/images/lianghuaImg1.jpg'),
    lianghuaConceptContent: '工业企业信息化和工业化融合',
    lianghua1: '工业化',
    lianghua2: '信息化',
    lianghua3: '机械化',
    lianghua4: '电气化',
    lianghua5: '自动化',
    lianghua6: '数字化',
    lianghua7: '网络化',
    lianghua8: '智能化',
    whatLianghua: "什么是“两化融合管理体系”？",
    whatLianghuaContent: '“两化融合管理体系”是一套指导企业推进两化融合的管理方法论；是打造信息化环境下竞争力的管理方法论；通过管理优化解决企业开展两化融合的内生动力。',
    processe: '两化融合管理体系将信息化环境下新型能力的打造分为以下过程：',
    processe1: '能力识别',
    processe2: '能力打造策划',
    processe3: '能力建设',
    processe4: '能力测评',
    processe5: '能力改进',
    Transformation: "两化数字化转型",
    TransformationContent: '新一代信息技术驱动新一轮产业变革加速演变',
    TransformationContent1: '新一轮技术引领新一轮技术变革',
    data: '大数据 Data',
    cloud: '云计算 Cloud',
    network: '5G',
    AI: '人工智能 AI',
    MI: "移动互联网 MI",
    BC: "区块链 BC",
    TransformationContent2: '融合应用催生新技术、新产品、新模式、新业态',
    technology: "新技术",
    Products: '新产品',
    Models: "新模式",
    BusinessModels: '新业态',
    Transformation1: '绿色可持续发展',
    Transformation2: '全要素生产效率',
    Transformation3: '产业结构持续优化',
    Transformation4: '技术创新和融\n合推动产业组\n合逻辑和体系\n深刻变革',
    PresentationForm: "两化在企业端的展现形式",
    PresentationFormTitle1: '生产自动化',
    PresentationFormContent1: '采用先进的信息技术和自动化设备计算机辅助设计（CAD）计算机辅助制造（CAM）数控机床、计算机辅助工程（CAE）CAPP、PLC工控系统、3D打印',
    PresentationFormTitle2: '产品智能化',
    PresentationFormContent2: '产品采用信息技术或嵌入智能模块如： 消费电子产品，智能汽车，智能家居， HUD抬头显示，防爆玻璃等',
    PresentationFormTitle3: '管理信息化',
    PresentationFormContent3: '信息技术应用于企业业务各个环节：单项应用：设计、生产、制造、采购、销售、财务、OA、项目管理等综合集成：ERP、MES、PLM、SCM、BI……',
    PresentationFormTitle4: '制造服务化',
    PresentationFormContent4: '从提供产品转向提供整体解决方案；进入生产性服务业如：搭建产业协同的创新/电商平台；西门子/GE；进入信息服务业',
    embodiment: "价值体现",
    embodimentImg1: require('@/assets/images/embodiment1.png'),
    embodimentImg2: require('@/assets/images/embodiment2.png'),
    embodimentImg3: require('@/assets/images/embodiment3.png'),

  },
  // 成熟度
  IntelligentManufacturing: {
    title: "诊断咨询服务·智能制造成熟度",
    manufacturingEnterprises: '面向制造企业',
    manufacturingEnterprisesSubtitle: '识别短板，能力提升',
    manufacturingEnterprisesTitle1: '智能制造能力提升',
    manufacturingEnterprisesContent1: '识别企业与同行间的差距，确定投资改进方向',
    manufacturingEnterprisesTitle2: '获得主管部⻔资金奖励',
    manufacturingEnterprisesContent2: '各地方工业和信息化主管部⻔鼓励企业开展智能制造成熟度评估，根据不同评估结果给予不同程度的资金奖励',
    manufacturingEnterprisesTitle3: '参与国家标杆示范遴选',
    manufacturingEnterprisesContent3: '智能制造能力成熟度等级较高的企业优先推荐为标杆/示范企业',
    manufacturingEnterprisesTitle4: '市场采信',
    manufacturingEnterprisesContent4: '参与市场招投标活动，获得评估证书的企业有增值加分，具备商务优势',
    subjects: '解决三类主体面临的难点',
    subjectsTitl1: '制造企业',
    subjectsContent1: '识差距：了解自身所处的现状，认识差距 定目标：明确发展目标，明确投资 后评价：对项目建设效果进行评价',
    subjectsTitl2: '系统解决方案提供商',
    subjectsContent2: '明需求：把握甲方的需求，目标清晰 识方法：理清弱项，对症下药 找路径：统一技术实现的路径和方法',
    subjectsTitl3: '主管部⻔',
    subjectsContent3: '看数据：掌握辖区内企业的智能制造水平 树标杆：选择辖区内优秀企业作为标杆示范 立政策：判断智能制造工作的阶段性成果和后续扶持方向',
    subjectsTitl4: '用成熟度理论来解决复杂系统问题',
    subjectsContent4: '智能制造现状诊断和发展路径的问题\n以客观数据为主管部⻔提供决策支撑',
    maturity: "智能制造能力成熟度(CMMM)要求",
    maturityImg: require('@/assets/images/cmmmm.png'),
    maturityModel: "智能制造能力成熟度模型—5个等级",
    maturityModelTitle1: '一级',
    maturityModelSubtitle1: '流程化管理',
    maturityModelContent1: '企业应开始对实施智能制造的基础和条件进行规划，能够对核心 业务 (设计、生产、物流、销 售、服务)进行流程化管理',
    maturityModelTitle2: '二级',
    maturityModelSubtitle2: '数字化改造',
    maturityModelContent2: '企业应采用自动化技术、信息技术手段对核心装备和业务等进行改造和规范，实现单一业务的数据共享',
    maturityModelTitle3: '三级',
    maturityModelSubtitle3: '网络化集成',
    maturityModelContent3: '企业应对装备、系统等开展集成，实现跨业务间的数据共享',
    maturityModelTitle4: '四级',
    maturityModelSubtitle4: '智能化生产',
    maturityModelContent4: '企业应对人员、资源、制造等进行数据挖掘，形成知识、模型等，实现对核心业务的精准预测和优化',
    maturityModelTitle5: '五级',
    maturityModelSubtitle5: '产业链创新',
    maturityModelContent5: '企业因基于模型持续驱动业务优化和创新，实现产业链协同并衍 生新的制造模式和商业模式',
    cmmmTitle1: 'CMMM可提供系统的、完整的、可量化的解决方案',
    cmmmTitle2: 'CMMM评估流程',
    cmmm1Img: require('@/assets/images/cmmm1.png'),
    cmmm2Img: require('@/assets/images/cmmm2.png'),
  },
  // 其他
  other: {
    title: '诊断咨询服务·其他',
    Industrial: require('@/assets/images/Industrial.png')
  },
  // 集成转型服务
  integration: {
    case: "客服案例",
    title1: '上海米其林轮胎有限公司',
    introduce1: "基于AI工业视觉识别的数据采集",
    ex1: '2022年智能制造优秀场景',
    achievement1: '“可制造性设计” \n “数字孪生工厂建设” \n “工厂数字化设计”',
    title2: '中天钢铁集团（南通）有限公司',
    introduce2: "“两化融合”、“智能制造”的咨询服务",
    ex2: '2022年智能制造示范工厂',
    achievement2: '“铁前集控智能制造示范工厂”',
    AIEecognition: 'AI机器视觉识别\n硬件模组',
    configuration: '配置',
    communication: "通信",
    Camera: "摄像头",
    light: "光源",
    AIBOX: 'AI BOX',
    Parameters: "参数",
    AIBOXParameters: 'AIBOX:1.3TOPS~21TOPS',
    BuiltIn: "内置",
    Pixels: "摄像头：1.2MP-2.0MP像素",
    ParametersContent: '常亮光源，触发光源，频闪光源  \n 光源强度：2000lux~3000lux',
    directional: "软件的定向研发和复制",
    directionalTitle1: '工业数据平台',
    directionalContent1: '祐云工业数据虚拟总线技术 \n 工业数据统计分析引擎 \n 设备数据管理与回放技术 \n基于物联网的制程追溯技术',
    directionalTitle2: '通用视觉传感器',
    directionalContent2: '基于普通工业相机的开发 \n 智能3D视觉传感技术 \n 基于Windows或Lunix的环境开发 \n嵌入式光源驱动调节技术',
    directionalTitle3: '精密驱控技术',
    directionalContent3: '多传感器融合标定 \n 工程安装组态控制 \n 高性能多轴运动控制平台',
    directionalTitle4: '机器视觉算法',
    directionalContent4: '2D视觉算法 \n 基于深度学习的缺陷检测 \n 3D视觉算法 \n 无监督学习算法',
    DataIntegration: require('@/assets/images/Data-Integration.png'),
    DigitalTwins: require('@/assets/images/Digital-twins.png')
  },
  // 智慧园区
  smartPark: {
    Security: "综合安防系统",
    title1: "戴口罩识别",
    content1: '支持戴口罩人脸识别，识别率>92% \n支持1:1、1:N 人脸比对',
    title2: "红外测温模块",
    content2: '0.3米~1.5米无感测额头体温 ，体温异常报警 \n160*120 上万像素非制冷焦平面红外测温',
    title3: "超强逆光处理",
    content3: '支持人脸曝光，根据强光自动调整 \n支持夜间3D降噪，无惧黑暗',
    title4: "属性识别丰富多彩",
    content4: '支持是否带口罩检测 \n支持人种、安全帽、肤色等检测',
    title5: "识别快速精准",
    content5: '0.2秒内完成人脸识别，准确率高于99% \n识别人脸容量可达5万/10万人,2米之内无感识别',
    title6: "可根据具体要求 \n进行研发随申码身份证识别",
    navigation: '智慧导航系统',
    navigationSubtitle: '无人机扫描 ╳ 实时三维建模',
    parking: '智慧停车系统',
    parkingTitle1: '停车体验提升',
    parkingContent1: '园区车位导航，最快找到可用车位\n专用车位（VIP）保障\n一键寻车指引，快速找到车辆',
    parkingTitle2: '车场拥堵治理',
    parkingContent2: '车牌快速识别，核心技术保障快速出入\n入场疏导：入口车辆限制和空闲区域指导\n出口疏导：各出口拥堵预警，规划快速离场路线',
    parkingTitle3: '数字化运营',
    parkingContent3: '去人工化和普及电子支付，提升运营效率\n出入车辆可实时统计，车辆轨迹跟踪，停车场\n“乱停车”等快速处理',
  },
  // 智慧建筑
  smartBuildings: {
    OverallStructure: '整体架构',
    integration: '智慧建筑数据集成平台',
    attendance: '无感考勤系统',
    attendanceSubtitle: '我们打造的人员实名制考勤系统，以企业劳务管理流程为主线，综合应用人脸识别、移动互联网等技术，着力打造同时满足封闭式环境、开放式环境、地下空间等多个应用场景的考勤手段，实现劳务管理全过程信息化。',
    SafetyInspection: '安全巡检',
    SafetyInspectionSubtitle: '通过带有祐云开发APP的手持机（采用4G/５G传输），配合RFID以及二维码等中间件技术，建立施工现场隐患检查体系，让安全员用手持设备PDA，完成对施工现场的移动巡检和隐患拍摄，隐患数据和巡检记录都会上传到智慧工地总平台，方便进行追溯和管理。',
    SpecialEquipment: '特种设备及人员操作监控',
    SpecialEquipmentTitle1: '扬尘监管模块',
    SpecialEquipmentContent1: '扬尘监管-PM2.5、PM10、TSP 、噪声等多个指标，视频现场取证，精准无异。智慧工地总平台对接多家扬尘噪音实时监测设备，全天候全自动无人值守，24小时全天候实时的在线监测，同时自动对接墙面，塔吊，雾炮等多种喷淋设备，可以做到无延时处理。',
    SpecialEquipmentTitle2: '场景化视频监控',
    SpecialEquipmentContent2: '环顾全景，洞悉细节”，高空全景监测，现场作业、总体进度一目了然；高倍放大近景，施工细节完整展现。AI行为识别预警：对不佩戴安全帽，禁行闯入，攀爬，打架，躺倒等行为进行识别预警。',
    SpecialEquipmentTitle3: '机械设备操作人员身份识别',
    SpecialEquipmentContent3: '将人脸识别技术应用到建筑行业，规范现场施工机械安全运作。操作人员必须专人专岗，杜绝了代刷卡，冒名操作等不安全因素。将现代信息技术融入精细化安全管理模式当中，让现代新技术助力传统施工安全工作。',
    wisdom4: require('@/assets/images/wisdom4.png')
  }
}
