import HeightCom from '../components/heightCom.vue'
import Header from '../components/header.vue'
import titleCom from '../components/title.vue'
import contentTitle from '../components/content-title.vue'
export default {
  install(app) {
    app.component(HeightCom.name, HeightCom)
    app.component(Header.name, Header)
    app.component(titleCom.name, titleCom)
    app.component(contentTitle.name, contentTitle)
  }
}