import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/common.less'
import '@/assets/font/font.css'
import ucloudy from '@/lib/directive.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 国际化配置
import i18n from './lang'
const app = createApp(App)
app.use(ElementPlus).use(store).use(router).use(ucloudy).use(i18n).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
