import { createStore } from 'vuex'
export default createStore({
  state: {
    lang: localStorage.getItem('ucWebLang') || 'zh',
  },
  getters: {
  },
  mutations: {
    SET_LANG: (state, lang) => {
      state.lang = lang

    },

  },
  actions: {

  },
  modules: {
  }
})
