<template>
  <Header />
  <p :class="lang == 'en' ? 'spanflex' : ''">
    <span>{{ $t(title) }}</span>
    <span>{{ content }}</span>
  </p>
  <img class="titleImg" :src="img" alt="">
</template>
<script>
export default {
  name: 'titleCom'
}
</script>
<script setup name=''>
import { useStore } from 'vuex'
import { computed } from 'vue'
defineProps({
  img: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: 'global.IntelligentManufacturing'
  },
  content: {
    type: String,
    default: ''
  }
})
const store = useStore()
const lang = computed(() => {
  return store.state.lang
})
</script>

<style scoped lang="less">
@media screen and (max-width:992px) {
  p {
    padding-left: 8vw;
    color: #0E0C0D;
    padding: 21.333vw 0 5.333vw 15px;
    position: absolute;
    z-index: 8;

    span:nth-child(1) {
      font-size: 8vw;
      font-weight: bold;
    }

    span:nth-child(2) {
      display: block;
      font-size: 4.8vw;
      margin-top: 10px;
    }
  }

  img {
    width: 100%;
    height: 127.2vw;
    margin-top: 48vw;
  }
}

@media screen and (min-width:993px) {
  p {
    padding-left: 60px;
    color: #0E0C0D;
    margin: 200px 0 70px;
    position: absolute;
    z-index: 8;

    span:nth-child(1) {
      font-size: 72px;
      margin-right: 70px;
      font-weight: bold;
    }

    span:nth-child(2) {
      font-size: 52px;
    }
  }

  img {
    width: 100%;
    height: 1000px;
    margin-top: 370px;
  }

  .spanflex {
    margin-top: 134px;

    span {
      display: block;
    }
  }
}
</style>