export default {
  global: {
    CapabilityAreas: "Capability Areas",
    // CapabilityAreasE: '',
    IntelligentManufacturing: 'Intelligent Manufacturing',
    lianghua: 'Integration of Informatization and Industrialization',
    ProductsServices: "Products and Services",
    IntegrationTransformation: 'Integration Transformation Services',
    LowCarbon: 'Low Carbon Transformation',
    DataIntegration: 'Data Integration',
    DigitalTwin: 'Digital Twin',
    SmartBuildings: 'Smart Buildings',
    SmartParkTitle: 'Smart Park',
    GreenFactory: 'Green Factory',
    IndustrialInternet: 'Industrial Internet',
    videoUrl: 'https://video.ucloudy.cn/vod-7d3fbf/e33be9906bd371eebff25017e1e90102/0ddef63e938a05632970e81c069c9ccf-sd.m3u8'
  },
  header: {
    Solutions: 'Solutions',
    About: 'About',
    DiagnosticConsulting: 'Diagnostic Consulting Services',
    grade: 'Intelligent Manufacturing Maturity',
    AI: 'AI Machine Vision Recognition',

  },
  // 主页
  home: {
    DigitalSmart: 'Digital Transformation and \n Smart Refurbishment',
    SmartFactory: 'Smart Factory Diagnosis and Consulting',
    Innovation: 'Innovation and Technology Attributes Cultivation',
    DigitalTransformation: 'Digital Transformation Services',
    PolicyGuidance: 'Policy Guidance and Training',
    MainBusiness: 'Main Business',
    MainBusinessTitle: "System Certification and Compliance",
    MainBusiness1: 'Smart Manufacturing Maturity Model',
    MainBusiness2: 'Integration of Informatization and Industrialization',
    MainBusiness3: 'Specialized Guidance for Excellence and Innovation',
    MainBusiness4: 'Industrial Internet',
    IntegratedMobile: require('@/assets/images/IntegratedMobileEn.png'),
    IntegratedPc: require('@/assets/images/IntegratedPcEn.png'),
    Integrated: 'Integrated Services',
    Integrated1: 'UCloudy',
    Integrated2: 'Factory',
    Integrated3: 'Basic Network',
    Integrated4: 'Industrial Software',
    Integrated5: 'Intelligent Equipment',
    Integrated6: 'Engineering Design',
    PolicyDeclaration: 'Policy Declaration',
    PolicyDeclaration1: 'Intelligent Manufacturing Workshop',
    PolicyDeclaration2: 'Intelligent Manufacturing Factory',
    PolicyDeclaration3: 'Intelligent Manufacturing Demonstration Scenarios',
    PolicyDeclaration4: 'Our Advantages',
    Products: require('@/assets/images/Products-pc1.png'),
    Solutions: 'Solutions',
    SolutionsName1: 'Fortune 500 Foreign-invested Enterprises',
    SolutionsTitle1: 'Tire Traceability System',
    Solutions1: 'Software and Hardware Development',
    Solutions2: 'AI Visual Recognition',
    Solutions3: 'Production Line Positioning',
    Solutions4: 'Warehouse Entry and Exit Integration',
    SolutionsName5: 'A-Share Listed Companies',
    SolutionsTitle6: 'Battery Diaphragm Quality Inspection System',
    Solutions7: 'Defect Detection',
    Solutions8: 'Full Process (MES) Integration',
    Solutions9: 'Hardware Design',
    Solutions10: 'Software Development',
    SolutionsName11: 'Taiwan Rubber Enterprises',
    SolutionsTitle12: 'Rubber Block Defect Recognition System',
    Solutions13: 'Custom Hardware and Software',
    Solutions14: 'Production Line Positioning',
    Solutions15: 'AI Visual Recognition',
    SolutionsName16: 'Experimental Teaching',
    SolutionsTitle17: 'Comprehensive Smart Laboratory Solutions',
    Solutions18: 'LLM (Abbreviation)',
    Solutions19: 'Open Recognition',
    Solutions20: 'Multi-Modal Fusion',
    Solutions21: 'Laboratory Environment Simulation',
    SolutionsName22: 'Fortune 500 Foreign-invested Enterprises',
    SolutionsTitle23: 'Dual Carbon Traceability System',
    Solutions24: 'Carbon Accounting',
    Solutions25: 'Dual Carbon Deduction',
    Solutions26: 'Carbon Data Modeling',
    Solutions27: 'DTC+ Customization + Combination',
    DiagnosticServices: "Diagnostic Services for Clients",
    DiagnosticServices1: 'Serving Nearly 200 Manufacturing Enterprises in the Yangtze River Delta Region',
    Technology: 'Technology Partners',
    About: 'About Us',
    AboutContent: "UCloudy is a typical technology-oriented enterprise. The core team primarily consists of Ph.D. and Master's degree holders with international study and work experience in the United States. The company's core technology revolves around AI industrial machine vision equipment and related solutions, which are widely applied in industrial production for smart manufacturing. Additionally, the company offers a range of integrated services to clients, including basic network construction (especially 5G private networks), server deployment, data platforms, and more.UCloudy is included in the directory of intelligent factory diagnostic and transformation service providers by the Economic and Information Commission of Shanghai and the Industry and Information Technology Bureau of Nantong. Presently, the company has 5 National Smart Manufacturing Maturity Model assessors and 4 consultants specializing in the integration of informatization and industrialization.The company is a member of the China Telecom Terminal Industry Association, the GSMA (Global System for Mobile Communications) Association, the Shanghai Artificial Intelligence Development Alliance, the Shanghai 5G Industry Alliance, and the China Intelligent Manufacturing System Solution Providers Alliance. It also serves as a graduate training base for Shanghai University and Shanghai Second Polytechnic University.In 2023, UCloudy became a supervisory unit of the Shanghai Smart Manufacturing Industry Association under the Shanghai Economic and Information Commission. It is designated as an intelligent factory diagnostic and transformation service provider by the Songjiang District, Minhang District, and Nantong Municipal Government. The company is one of the few leading enterprises in the domestic smart manufacturing field that can provide diagnostic consulting and digital transformation integrated services.",
    Contact: 'Contact Us',
    YourName: 'Your Name',
    Phone: 'Phone',
    Consultation: 'Consultation Content',
    Submit: 'Submit',
    Email: 'Email',
    Address: 'Address',
    AddressDetails: '505, Building A, No. 58 Xiangke Road, Pudong New Area, Shanghai',
    phone1: 'Phone / 186-1652-9115',
    Address1: 'Address / 505, Building A, No. 58 Xiangke Road, Pudong New Area, Shanghai',
    Email1: `Email / john{'@'}ucloudy.cn`,
  },
  // 两化
  lianghua: {
    title: "Diagnostic Consulting Services for Integration of Informatization and Industrialization",
    CustomerCases: 'Customer Cases',
    CustomerCases1: 'Zhongtian Steel Group (Nantong) Co., Ltd.',
    CustomerCases2: 'Shanghai Dafeng Laboratory Equipment Co., Ltd.',
    CustomerCases3: 'Suzhou Aerospace Aircraft Components Co., Ltd.',
    lianghuaConcept: 'Current Concepts of Integration of Informatization and Industrialization',
    lianghuaConceptContent: 'Industrial Enterprise Informatization and Industrialization Integration',
    lianghua1: 'Industrialization',
    lianghua2: 'Informatization',
    lianghua3: 'Mechanization',
    lianghua4: 'Electrification',
    lianghua5: 'Automation',
    lianghua6: 'Digitalization',
    lianghua7: 'Networking',
    lianghua8: 'Smartization',
    whatLianghua: 'What is the "Integration of Informatization and Industrialization Management System"?',
    whatLianghuaContent: 'The "Integration of Informatization and Industrialization Management System" is a set of guidelines and methodologies for guiding enterprises in advancing the integration of informatization and industrialization. It is a management methodology aimed at enhancing competitiveness within an information-driven environment. By optimizing management practices, it addresses the internal driving forces that facilitate the integration of informatization and industrialization within companies.',
    processe: 'The Integration of Informatization and Industrialization Management System divides the creation of new capabilities in an information-driven environment into the following processes:',
    processe1: 'Capability Identification',
    processe2: 'Capability Development Planning',
    processe3: 'Capability Building',
    processe4: 'Capability Assessment',
    processe5: 'Capability Improvement',
    Transformation: "Integration and Digital Transformation of Informatization and Industrialization",
    TransformationContent: 'The New Generation of Information Technology Driving Accelerated Evolution in Industrial Transformation and Upgrading',
    TransformationContent1: 'New Technology Leading a New Wave of Technological Revolution',
    data: 'Data',
    cloud: 'Cloud',
    network: '5G',
    AI: 'AI',
    MI: "MI",
    BC: "BC",
    TransformationContent2: 'Convergent Applications Giving Rise to New Technologies, New Products, New Models, and New Industries',
    technology: "Emerging Technologies",
    Products: 'Novel Products',
    Models: "Innovative Models",
    BusinessModels: 'New Business Models',
    lianghuaImg: require('@/assets/images/lianghuaImg2.jpg'),
    Transformation1: 'Green and Sustainable Development',
    Transformation2: 'Total Factor Productivity',
    Transformation3: 'Continuous Optimization of Industrial Structure',
    Transformation4: 'Technological Innovation and Integration Driving Profound Transformation of Industrial Composition Logic and System',
    PresentationForm: "The Manifestation of Informatization and Industrialization at the Enterprise Level",
    PresentationFormTitle1: 'Production Automation',
    PresentationFormContent1: 'Using Advanced Information Technology and Automation Equipment:Computer-Aided Design (CAD)、Computer-Aided Manufacturing (CAM)、Numerical Control Machine Tools, Computer-Aided Engineering (CAE)、Computer-Aided Process Planning (CAPP), Programmable Logic Controller (PLC) Control Systems, 3D Printing',
    PresentationFormTitle2: 'Product Intelligence',
    PresentationFormContent2: 'Products Utilizing Information Technology or Embedded Smart Modules,Examples include Consumer Electronics, Smart Cars, Smart Homes, HUD (Head-Up Display), Bulletproof Glass…',
    PresentationFormTitle3: 'Information Management',
    PresentationFormContent3: 'Application of Information Technology Across Various Business Functions in Enterprises;Single Applications: Design, Production, Manufacturing, Procurement, Sales, Finance, OA, Project Management…;Comprehensive Integration: ERP, MES, PLM, SCM, BI…',
    PresentationFormTitle4: 'Manufacturing Serviceization',
    PresentationFormContent4: 'Transitioning from Providing Products to Offering Comprehensive Solutions; Entering the Productive Services Industry.Examples include Establishing Innovative/E-commerce Platforms for Industry Collaboration; Siemens/GE Entering the Information Services Industry.',
    embodiment: "Value embodiment",
    embodimentImg1: require('@/assets/images/embodiment4.png'),
    embodimentImg2: require('@/assets/images/embodiment5.png'),
    embodimentImg3: require('@/assets/images/embodiment6.png'),
  },
  // 成熟度
  IntelligentManufacturing: {
    title: "Diagnostic Consulting Services - Intelligent Manufacturing Maturity",
    manufacturingEnterprises: 'Targeted at Manufacturing Enterprises',
    manufacturingEnterprisesSubtitle: 'Identifying Weaknesses and Enhancing Capabilities',
    manufacturingEnterprisesTitle1: 'Enhancing Intelligent Manufacturing Capabilities',
    manufacturingEnterprisesContent1: 'Identifying the Gap between the Enterprise and Peers and Determining Investment Improvement Directions',
    manufacturingEnterprisesTitle2: 'Obtaining Financial Incentives from Supervisory Authorities',
    manufacturingEnterprisesContent2: 'Various Industrial and Information Authorities at the Local Level Encourage Enterprises to Conduct Intelligent Manufacturing Maturity Assessments and Provide Different Levels of Financial Incentives Based on Assessment Results',
    manufacturingEnterprisesTitle3: 'Participating in the Selection of National Benchmark Demonstrations',
    manufacturingEnterprisesContent3: 'Enterprises with Higher Levels of Intelligent Manufacturing Maturity Are Prioritized as Benchmark/Demonstration Enterprises',
    manufacturingEnterprisesTitle4: 'Market Acceptance',
    manufacturingEnterprisesContent4: 'Participating in market bidding activities and obtaining evaluation certificates can result in added value and provide a competitive advantage in the business.',
    subjects: 'Addressing the Challenges Faced by Three Types of Entities',
    subjectsTitl1: 'Manufacturing Enterprises',
    subjectsContent1: 'Gap Identification: Understanding their Current Situation and Recognizing Discrepancies.Goal Setting: Clearly Defining Development Objectives and Investment.Post-Evaluation: Evaluating the Effectiveness of Project Construction',
    subjectsTitl2: 'System Solution Providers',
    subjectsContent2: 'Needs Clarification: Grasping the Requirements of the First Party with Clear Objectives.Method Recognition: Identifying Weaknesses and Tailoring Solutions.Pathfinding: Establishing Unified Technical Implementation Paths and Methods',
    subjectsTitl3: 'Supervisory Authorities',
    subjectsContent3: 'Data Assessment: Understanding the Level of Intelligent Manufacturing in Enterprises within Their Jurisdiction.Benchmarking: Selecting Outstanding Enterprises within Their Jurisdiction as Demonstrations.Policy Formulation: Assessing the Phased Achievements and Subsequent Support Directions for Intelligent Manufacturing Initiatives',
    subjectsTitl4: 'Solving Complex System Problems Using Maturity Theory',
    subjectsContent4: 'Objective Data to Provide Decision Support for Supervisory Authorities on the Current Status Diagnosis and Development Path of Intelligent Manufacturing',
    maturity: "Requirements for Intelligent Manufacturing Capability Maturity Model",
    maturityImg: require('@/assets/images/cmmmm1.png'),
    maturityModel: "Intelligent Manufacturing Capability Maturity Model - 5 Levels",
    maturityModelTitle1: 'Ⅰ',
    maturityModelSubtitle1: 'Process Management',
    maturityModelContent1: 'Enterprises Should Begin Planning the Foundation and Conditions for Implementing Intelligent Manufacturing, Capable of Managing Core Business Functions (Design, Production, Logistics, Sales, Service) through Process Management',
    maturityModelTitle2: 'Ⅱ',
    maturityModelSubtitle2: 'Digital Transformation',
    maturityModelContent2: 'Enterprises Should Use Automation and Information Technology to Transform and Standardize Core Equipment and Operations, Achieving Data Sharing for Individual Business Functions',
    maturityModelTitle3: 'Ⅲ',
    maturityModelSubtitle3: 'Networked Integration',
    maturityModelContent3: 'Enterprises Should Integrate Equipment, Systems, etc., Enabling Data Sharing Across Business Functions',
    maturityModelTitle4: 'Ⅳ',
    maturityModelSubtitle4: 'Intelligent Production',
    maturityModelContent4: 'Enterprises Should Mine Data for Personnel, Resources, Manufacturing, etc., Creating Knowledge, Models, etc., to Achieve Precise Prediction and Optimization of Core Business Functions.',
    maturityModelTitle5: 'Ⅴ',
    maturityModelSubtitle5: 'Industrial Chain Innovation',
    maturityModelContent5: 'Enterprises Continuously Drive Business Optimization and Innovation Based on Models, Achieving Collaborative Industrial Chain Efforts and Deriving New Manufacturing and Business Models.',
    cmmmTitle1: 'CMMM Can Provide Systematic, Comprehensive, and Quantifiable Solutions',
    cmmmTitle2: 'CMMM Assessment Process',
    cmmm1Img: require('@/assets/images/cmmm3.png'),
    cmmm2Img: require('@/assets/images/cmmm4.png'),
  },
  // 其他
  other: {
    title: 'Diagnostic Consulting Services·',
    Industrial: require('@/assets/images/Industrial1.png')
  },
  // 集成转型服务
  integration: {
    case: "Customer Cases",
    title1: 'Michelin Shanghai Tyre Co., Ltd.',
    introduce1: "Data Collection Based on AI Industrial Vision Recognition",
    ex1: 'Excellent Smart Manufacturing Scenarios in 2022',
    achievement1: '“Design for Manufacturability” \n “Digital Twin Factory Construction” \n “Digital Design for Manufacturing”',
    title2: 'Central Iron & Steel Group (Nantong) Co., Ltd.',
    introduce2: 'Consulting Services for "Integration of Informatization and Industrialization" and "Smart Manufacturing"',
    ex2: '2022 Smart Manufacturing Demonstration Factory',
    achievement2: '"Tieqianjiakong Smart Manufacturing Demonstration Factory"',
    AIEecognition: 'AI Machine Vision Recognition Hardware Module',
    configuration: 'Configuration',
    communication: "Communication",
    Camera: "Camera",
    light: "Light Source",
    Parameters: "Parameters",
    AIBOXParameters: 'AIBOX:1.3TOPS~21TOPS',
    AIBOX: 'AI BOX',
    BuiltIn: "Built-in",
    Pixels: "Camera: 1.2MP-2.0MP Pixels",
    ParametersContent: 'Constant Light Source, Trigger Light Source, Strobe Light Source  \n Light Intensity: 2000 lux ~ 3000 lux',
    directional: "Targeted Research and Replication of Software",
    directionalTitle1: 'Industrial Data Platform',
    directionalContent1: 'Youyun Industrial Data Virtual Bus Technology \n Industrial Data Statistical Analysis Engine \n Equipment Data Management and Playback Technology \n Process Traceability Technology Based on the Internet of Things',
    directionalTitle2: 'General Vision Sensors',
    directionalContent2: 'Development Based on Ordinary Industrial Cameras \n Intelligent 3D Vision Sensing Technology \n Development in Windows or Linux Environment \n Embedded Light Source Drive Adjustment Technology',
    directionalTitle3: 'Precision Drive Control Technology',
    directionalContent3: 'Multi-Sensor Fusion Calibration \n Engineering Installation Configuration Control \n High-Performance Multi-Axis Motion Control Platform',
    directionalTitle4: 'Machine Vision Algorithms',
    directionalContent4: '2D Vision Algorithms \n Defect Detection Based on Deep Learning \n 3D Vision Algorithms \n Unsupervised Learning Algorithms',
    DataIntegration: require('@/assets/images/Data-Integration2.png'),
    DigitalTwins: require('@/assets/images/Digital-twins2.png')
  },
  // 智慧园区
  smartPark: {
    Security: "Comprehensive Security System",
    title1: "Mask Recognition",
    content1: 'Supports face recognition with mask-wearing, recognition rate >92% \nSupports 1:1 and 1:N face comparison',
    title2: "Infrared Temperature Measurement Module",
    content2: 'Non-contact forehead temperature measurement from 0.3 meters to 1.5 meters, with abnormal temperature alert \n 160x120 pixels uncooled focal plane infrared temperature measurement',
    title3: "Strong backlight processing",
    content3: 'Supports face exposure adjustments based on strong light \n	Nighttime 3D noise reduction support for darkness',
    title4: "Rich Attribute Recognition",
    content4: 'Supports mask-wearing detection \n Supports ethnicity, safety helmets, skin color, and other detections',
    title5: "Fast and Accurate Recognition",
    content5: 'Completes face recognition within 0.2 seconds, with accuracy greater than 99% \n Capacity for recognizing up to 50,000/100,000 faces, non-contact recognition within 2 meters',
    title6: "Identity card recognition based on specific requirements",
    navigation: 'Smart Navigation System',
    navigationSubtitle: 'Drone Scanning for Real ╳ Time 3D Modeling',
    parking: 'Smart Parking System',
    parkingTitle1: 'Enhancing Parking Experience',
    parkingContent1: '	Park Navigation for Quickly Finding Available Parking Spaces\nDedicated Parking Spaces (VIP) Guaranteed\nOne-Click Vehicle Location Guidance for Finding Your Vehicle Quickly',
    parkingTitle2: 'Parking Lot Congestion Management',
    parkingContent2: '	Rapid License Plate Recognition, Core Technology for Swift Entry and Exit\n	Entry Management: Entrance Vehicle Restrictions and Guidance to Vacant Areas\n	Exit Management: Congestion Warnings at Various Exits and Planning of Fast Exit Routes',
    parkingTitle3: 'Digital Operations',
    parkingContent3: 'Transition to Automation and Widespread Electronic Payments to Improve Operational Efficiency\n Real-Time Statistics of Entering and Exiting Vehicles, Vehicle Tracking, and Rapid Handling of Parking Violations and Disruptions',
  },
  // 智慧建筑
  smartBuildings: {
    OverallStructure: 'Overall Architecture',
    integration: 'Smart Building Data Integration Platform',
    attendance: 'Contactless Attendance System',
    attendanceSubtitle: 'We have developed a personnel real-name attendance system that centers around enterprise labor management processes. It integrates technologies such as facial recognition and mobile internet to create attendance solutions suitable for various scenarios, including closed environments, open spaces, and underground spaces. This system aims to achieve end-to-end information management in labor services.',
    SafetyInspection: 'Safety inspection',
    SafetyInspectionSubtitle: 'By using handheld devices equipped with the Yun development app (utilizing 4G/5G transmission) and in conjunction with middleware technologies such as RFID and QR codes, an on-site construction hazard inspection system has been established. Safety officers use handheld PDA devices to conduct mobile inspections and capture hazard information at the construction site. The hazard data and inspection records are then uploaded to the smart construction site central platform for easy traceability and management.',
    SpecialEquipment: 'Special Equipment and Personnel Operation Monitoring',
    SpecialEquipmentTitle1: 'Dust Control Module',
    SpecialEquipmentContent1: 'Dust Control - Monitoring PM2.5, PM10, TSP (Total Suspended Particles), noise, and various indicators with video evidence, providing precise and reliable data. The Smart Construction Site central platform connects to multiple real-time dust and noise monitoring devices, operating 24/7 without human intervention, ensuring continuous and real-time online monitoring. It also seamlessly integrates with wall-mounted, tower crane, and fog cannon equipment, enabling real-time, delay-free response.',
    SpecialEquipmentTitle2: 'Scenario-Based Video Surveillance',
    SpecialEquipmentContent2: '"Surveying the panoramic view, discerning details" - High-altitude panoramic monitoring offers a comprehensive overview of on-site operations and overall progress. High-level zoom allows for detailed display of construction activities.	AI Behavior Recognition and Early Warning: Identifying and warning against behaviors such as failure to wear safety helmets, trespassing into restricted areas, climbing unauthorized structures, engaging in altercations, or lying down.',
    SpecialEquipmentTitle3: 'Mechanical Equipment Operator Identification',
    SpecialEquipmentContent3: 'The application of facial recognition technology in the construction industry standardizes the safe operation of on-site construction machinery. Operators are required to be assigned to specific roles, eliminating unsafe practices like proxy card usage and impersonation. Modern information technology is integrated into a detailed safety management model to harness the power of contemporary technology in advancing traditional construction safety practices.',
    wisdom4: require('@/assets/images/wisdom5.png')
  }
}