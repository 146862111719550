
// import { createApp } from 'vue'
// 引入自己的语言包
import zh from "./zh"; // 中文
import en from "./en"; // 英文
// 国际化
// import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n'
// createApp.use(VueI18n)
// 配置i18n语言包
const messages = {
  zh: {
    ...zh
  },
  en: {
    ...en
  }
}
// 国际化配置
const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('ucWebLang') || 'zh', // 默认中文
  messages
})

export default i18n
